import { isBrowser } from "@utils/isBrowser"
import {useState, useEffect} from "react";

export const useIsReady = () => {
    const [state, setState] = useState( isBrowser() ? window.document.readyState === "complete" : false);

    const handleDOM = () => {
        setState(true);
    }

    useEffect(() => {
        if(isBrowser() && window.document.readyState === "complete") setState(true)
        if(isBrowser() && window.document.readyState !== "complete" ) {
            window.addEventListener("load", handleDOM);
        }

        return  isBrowser() ? () => window.removeEventListener("load", handleDOM) : () => {}
    },[]);

    return [state];
}