import React, { useEffect, useState, useRef } from "react";
import styles from "./hero-bottom.module.scss";
import { graphql, useStaticQuery } from "gatsby";

import head from "../../../assets/image/head.png";
import arrowLeft from "../../../assets/svg/arrow-left.svg";
import compass from "../../../assets/svg/Compass.svg";
import { m, LazyMotion, domAnimation} from "framer-motion";
import { useTranslation } from "gatsby-plugin-react-i18next";


const query = graphql`
query {
  allFile(filter: {name: {in: ["DigitalMarketing2", "Campaigns", "Openbanking2", "RealtimeCampaigns2", "Analytics2", "CommercialSystematics2", "BPM", "Wallet", "FMC", "MobileBanking", "OnlineBankking", "ArtificialIntelligence2", "Realtimedecisions2", "Omnichannel2", "Incentivos2", "CommercialPipeline2", "DigitalOnboarding2"]}}) {
    nodes {
      name
      childImageSharp {
        fluid (maxWidth: 420){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
}

`

const HeroBottom = ({ monitorVisible, setMonitorVisible }) => {
  const { t } = useTranslation();

  const data = useStaticQuery(query);

  const arr = [
    {
      tag: t("HERO.SWIPER.DIGITAL_MARKETING"),
      image: "DigitalMarketing2",
      id: "f-digitalmarketing"
    },
    {
      tag: t("HERO.SWIPER.CAMPAINGNS"),
      image: "Campaigns",
      id: "f-camp"
    },
    {
      tag: t("HERO.SWIPER.OPENBANKING"),
      image: "Openbanking2",
      id: "f-openbanking"
    },
    {
      tag: t("HERO.SWIPER.RT_CAMPAINGNS"),
      image: "RealtimeCampaigns2",
      id: "f-realtimecamp"
    },
    {
      tag: t("HERO.SWIPER.ANALYTICS"),
      image: "Analytics2",
      id: "f-analytics"
    },
    {
      tag: t("HERO.SWIPER.COMMERCIAL_S"),
      image: "CommercialSystematics2",
      id: "f-commercialsystematics"
    },
    {
      tag: t("HERO.SWIPER.BPM"),
      image: "BPM",
      id: "f-bpm"
    },
    {
      tag: t("HERO.SWIPER.WALLET"),
      image: "Wallet",
      id: "f-wallet"
    },
    {
      tag: t("HERO.SWIPER.CRM"),
      image: "FMC",
      id: "f-crm"
    },
    {
      tag: t("HERO.SWIPER.MOBILE_BANKING"),
      image: "MobileBanking",
      id: "f-mobilebanking"
    },
    {
      tag: t("HERO.SWIPER.ONLINE_BANKING"),
      image: "OnlineBankking",
      id: "f-onlinebanking"
    },
    {
      tag: t("HERO.SWIPER.AI"),
      image: "ArtificialIntelligence2",
      id: "f-ai"
    },
    {
      tag: t("HERO.SWIPER.REALTIME"),
      image: "Realtimedecisions2",
      id: "f-realtimedecisions"
    },
    {
      tag: t("HERO.SWIPER.OMNICHANNEL"),
      image: "Omnichannel2",
      id: "f-omnichannelsolution"
    },
    {
      tag: t("HERO.SWIPER.INCENTIVES"),
      image: "Incentivos2",
      id: "f-incentives"
    },
    {
      tag: t("HERO.SWIPER.COMMERCIAL_P"),
      image: "CommercialPipeline2",
      id: "f-commercialPipeline"
    },
    {
      tag: t("HERO.SWIPER.DIGITAL_OB"),
      image: "DigitalOnboarding2",
      id: "f-digitalOnboarding"
    },
  ];

  const arrImage = arr.map( (x) => {
    const index = data.allFile.nodes.findIndex(y => { 
      return y.name === x.image 
    });
    const z = { ...x, ...data.allFile.nodes[index].childImageSharp };
    return z;
  })

  const [fadeUp, setFadeUp] = useState({ opacity: 1, y: 0 });
  const [display, setDisplay] = useState({ display: "unset" });
  const [width, setWidth] = useState(360);
  const [image, setImage] = useState();

  const items = useRef(arrImage.map(React.createRef));
  const [slide, setSlide] = useState(0);
  const [active, setActive] = useState(8);

  const hideMonitor = () => {
    setMonitorVisible(!monitorVisible);
  };

  useEffect(() => {
    setFadeUp({ opacity: 1, y: 0 });
    setDisplay({ display: "unset" });
    setMonitorVisible(true);
    setImage(arrImage[active].fluid);
  }, [active]);

  useEffect(() => {
    if (!monitorVisible) {
      setTimeout(() => { setDisplay({ display: "none" }); }, 1200);
      setFadeUp({ opacity: 0, y: 20 });
    }
    else {
      setDisplay({ display: "unset" });
      setFadeUp({ opacity: 1, y: 0 });
    }
  }, [monitorVisible]);

  const handleLeft = () => {
    setImage();

    const toLeft = items.current.map((el, idx) => el.current.offsetWidth);
    if (active > 0 && active <= arrImage.length - 1) {
      setSlide(prev => prev + (toLeft[active] / 2 + toLeft[active - 1] / 2));
      setActive(prev => prev - 1);
      setWidth(Math.abs(toLeft[active - 1]) + 60);

      items.current[active - 1].current.click();
    }
  };

  const handleRight = () => {
    setImage();

    const toRight = items.current.map((el, idx) => -el.current.offsetWidth);

    if (active >= 0 && active < arrImage.length - 1) {
      setSlide(prev => prev + (toRight[active] / 2 + toRight[active + 1] / 2));
      setActive(prev => prev + 1);
      console.log(Math.abs(toRight[active + 1]) + 60);
      setWidth(Math.abs(toRight[active + 1]) + 60);

      items.current[active + 1].current.click();
    }
  };

  return (
    <div className={styles.heroBottomContainer}>
      <LazyMotion features={domAnimation} >

      <m.div animate={display}>
        <m.div
          animate={fadeUp}
          transition={{ stiffness: 20, type: "spring" }}
          className={styles.parent}
          >
          <div className={styles.monitor}>
            {image && (
              <m.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
              style={{ width: "100%", height: "100%", margin: 0 }}
              src={image.srcWebp}
              alt={arrImage[active].tag}
              loading="lazy"
              />
              )}
          </div>
          <div className={styles.headPosition}>
            <img src={head} alt='Monitor' loading="lazy"/>
          </div>
        </m.div>
      </m.div>

      <div className={styles.explore} >
        <m.div 
          animate={{ opacity: 1 }}
          transition={{ stiffness: 8, type: "spring" }}
          style={{ opacity: 0 }}>
          <div className={styles.compass}>
            <img style={{ cursor: "pointer", width: "40px" }}
              onClick={hideMonitor}
              src={compass} 
              alt="explore"
              loading="lazy"
            />              
          </div>
          {/* <p>{t("HERO.EXPLORE")}</p> CORREGIR TRADUCCION */}
          <p>{t("explore")}</p>
        </m.div>
      </div>

      <div className={`${styles.heroSwipeText} relative`}>
        <m.div
          style={{
            position: "absolute",
            flexDirection: "row",
            display: "flex",
            left: "50%",
            top: "50%",
            width,
            zIndex: 2,
            transform: "translate(-50%, -50%)",
            justifyContent: "space-between",
          }}
          animate={{ width }}
          >
          <div
            onClick={handleLeft}
            className='d-flex'
            style={{ cursor: "pointer" }}
            >
            <img
              src={arrowLeft}
              alt='Left arrow'
              className={styles.rotateLeft}
              loading="lazy"
            />
          </div>

          <div
            onClick={handleRight}
            className='d-flex'
            style={{ cursor: "pointer" }}
            >
            <img
              src={arrowLeft}
              alt='Right arrow'
              className={styles.rotateRight}
              loading="lazy"
            />
          </div>
        </m.div>

        <div style={{ width: "100%", overflow: "hidden" }}>
          <m.div
            animate={{ x: slide }}
            transition={{ duration: 0.3 }}
            style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
            {arrImage.map((el, index) => {
              return (
                <div
                key={el.tag}
                  style={{ width: 300, pointerEvents: "none" }}
                  ref={items.current[index]}
                  id={el.id}
                >
                  <p className={index === active ? styles.active : ""}>
                    {el.tag}
                  </p>
                </div>
              );
            })}
          </m.div>
        </div>
      </div>
              </LazyMotion>
    </div>
  );
};

export default HeroBottom;
