import React, { useState} from "react";
import HeroSkeleton from "@organism/Hero/HeroSkeleton";
import HeroBottom from "@molecules/HeroBottom";
import { useIsReady } from "./../../../hooks/useIsReady";
import Chart3d from "@n5now/blueprint/Chart3d";
import styles from "./Chart3d.module.scss";

const Chart = () => {

    const [monitorVisible, setMonitorVisible] = useState(true);
    const [isReady] = useIsReady();
    // const [loader, setLoader] = useState(true)

    // setTimeout(() => {
    //     setLoader(false)
    // }, 1000)

    return (
        <section style={{ height: "100vh", width: "100%", position: "relative" }} className={"overflow-hidden text-center mb106"}>
        {
          isReady ? 
          <>
            <div style={monitorVisible ? { pointerEvents: "none" } : { pointerEvents: "auto" }}>
              <Chart3d />
              <div className={styles.block}></div>
            </div>
            
            <HeroBottom
              monitorVisible={monitorVisible}
              setMonitorVisible={setMonitorVisible}
            />
          </>
          : <HeroSkeleton />
        }
      </section>
    )
}

export default Chart;