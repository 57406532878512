import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const HeroSkeleton = () => {
    return (        
        <SkeletonTheme 
            color="#04272A" 
            highlightColor="#444" 
        >
            <p                 
                style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "8%"
            }}>
                <Skeleton style={{width: "40%"}} height={150}/>                    
            </p>
        </SkeletonTheme>        
    )
}

export default HeroSkeleton
